import React, { Component } from 'react';

import './App.css';
import VideoPermissionPage from "./VideoPermissionPage";
import GamePage from "./GamePage";

interface INNParams {
  multiplier: 0.25 | 0.5 | 0.75 | 1 | 1.01;
  imageScaleFactor: number;
  outputStride: 32 | 16 | 8;
}

export const VIDEO_SIZE = {
  width: 640,
  height: 480
}

export const NNPARAMS : INNParams = {
  multiplier: 0.5,
  imageScaleFactor: 0.5,
  outputStride: 16
}

enum SetupStage {
  VIDEO, DONE
}

interface IAppState {
  videoStream?: MediaStream;
  setupStage: SetupStage;
}

class App extends Component<{}, IAppState> {

  constructor(props: {}) {
    super(props);
    this.state = {
      setupStage: SetupStage.VIDEO,
      videoStream: undefined
    }

    this.setVideo = this.setVideo.bind(this);
  }

  render() {
    switch (this.state.setupStage) {
      case SetupStage.VIDEO:
        return <VideoPermissionPage onChange={this.setVideo}/>;
      case SetupStage.DONE:
        return <GamePage videoStream={this.state.videoStream}/>;
    }
  }

  public setVideo(stream: MediaStream) {
    this.setState({
      setupStage: SetupStage.DONE,
      videoStream: stream
    });
  }

}

export default App;
