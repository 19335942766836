import React, { Component } from 'react';

import './App.css';

interface IPuzzleCardsComponentProps {
  cards: number[];
  selectedCards: number[];
}

const CARD_WIDTH = 150+15;
const CARD_HEIGHT = 200+15;

export default class PuzzleCardsComponent extends Component<IPuzzleCardsComponentProps> {

  public constructor(props: IPuzzleCardsComponentProps) {
    super(props);
    this.drawCard = this.drawCard.bind(this);
  }

  render() {
    return (
      <div className="puzzleCardsContainer" style={{
        width: CARD_WIDTH*2 + 'px',
        height: CARD_HEIGHT*3 + 'px'
      }}>
        {this.props.cards.map(this.drawCard)}
      </div>
    )
  }

  private drawCard(cardNumber: number, cardIndex: number) {
    let tx, ty;
    if (cardIndex % 2 === 0) {
      tx = 0;
    } else {
      tx = CARD_WIDTH;
    }
    ty = Math.floor(cardIndex/2)*CARD_HEIGHT;
    const style: React.CSSProperties = {
      transform: `translate(${tx}px, ${ty}px)`
    }
    return (
      <div key={cardNumber} className={this.props.selectedCards.findIndex(value => value === cardIndex) > -1 ? "card selected" : "card"} style={style}>
        <img width={150} src={require("./images/card" + cardNumber + ".png")} />
      </div>
    )
  }

}