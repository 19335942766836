import React, { Component } from 'react';


interface IMusicProps {
  track: "theme" | "victory" | "none"
}

interface IMusicState {
  rootRef: React.RefObject<HTMLAudioElement>;
}

export default class Music extends Component<IMusicProps, IMusicState> {

  constructor(props: IMusicProps) {
    super(props);
    this.state = {
      rootRef: React.createRef(),
    }
  }

  render() {
    let src;
    switch (this.props.track) {
      case "theme":
        src = require("./music/theme.mp3");
        break;
      case "victory":
        src = require("./music/theme.mp3");
    }
    return (
      <>
        {this.props.track === "none" ? null :
          <audio datatype="audio/mpeg" style={{ display: "none" }} loop={true} ref={this.state.rootRef} src={require(`./music/${this.props.track}.mp3`)} autoPlay={true} />
        }
      </>
    )
  }

}