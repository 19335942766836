import React, { Component } from 'react';

import './App.css';
import { VIDEO_SIZE } from "./App";

interface IVideoPermissionPageProps {
  onChange: (stream: MediaStream) => void;
}


export default class VideoPermissionPage extends Component<IVideoPermissionPageProps> {

  render() {
    return (
      <span>Please enable video</span>
    )
  }

  public async componentDidMount() {
    const stream = await navigator.mediaDevices.getUserMedia({
      'audio': false,
      'video': {
        ...VIDEO_SIZE
      }
    });
    this.props.onChange(stream);
  }

}