import React, { Component } from 'react';

import './App.css';
import dd from './images/dd.png';
import dm from './images/dm.png';
import du from './images/du.png';
import md from './images/md.png';
import mm from './images/mm.png';
import mu from './images/mu.png';
import ud from './images/ud.png';
import um from './images/um.png';
import uu from './images/uu.png';
import unknown from './images/unknown.png';
import { ISides, Directions } from "./GamePage";

interface IPoseIndicatorProps {
  sides: ISides;
}


export default class PoseIndicator extends Component<IPoseIndicatorProps> {

  render() {
    return (
      <div className="poseIndicator">
        <img src={this.getImage(this.props.sides)} height="300px"/>
      </div>
    )
  }

  private getImage(slides: ISides) {
    const l = slides.left;
    const r = slides.right;
    if (l === Directions.UNKNOWN || r === Directions.UNKNOWN) {
      return unknown;
    }
    if (l === Directions.DOWN) {
      switch(r) {
        case Directions.DOWN:
          return dd;
        case Directions.MIDDLE:
          return dm;
        case Directions.UP:
          return du;
      }
    } else if (l === Directions.MIDDLE) {
      switch(r) {
        case Directions.DOWN:
          return md;
        case Directions.MIDDLE:
          return mm;
        case Directions.UP:
          return mu;
      }
    } else if (l === Directions.UP) {
      switch(r) {
        case Directions.DOWN:
          return ud;
        case Directions.MIDDLE:
          return um;
        case Directions.UP:
          return uu;
      }
    }
  }
 
}