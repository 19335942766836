import React, { Component } from 'react';
import { isEqual, shuffle } from 'lodash';

import './App.css';
import PoseDetection from "./PoseDetection";
import PoseDetectionResult from "./PoseDetectionResult";
import { Vector2D, Keypoint } from "@tensorflow-models/posenet/dist/types";
import PuzzleCardsComponent from "./PuzzleCardsComponent";
import PoseIndicator from "./PoseIndicator";
import Music from "./Music";

export interface ISides {
  left: Directions;
  right: Directions;
}

interface IGamePageProps {
  videoStream?: MediaStream;
}

interface IGamePageState {
  history: ISides[];
  historyCounter: number;
  lastDirections: ISides;
  startTime: number;
  currentCards: number[];
  expectedCards: number[];
  selectedCards: number[];
  endTime?: number;
}

export enum Directions {
  UP = "UP",
  MIDDLE = "MIDDLE",
  DOWN = "DOWN",
  UNKNOWN = "UNKNOWN"
}

interface ICardPositions {
  [index: string]: number;
}

export const MIN_PARTS_SCORE = 0.6;
const EQUALITY_COUNTER = 2;

const LEFT_CARDS: ICardPositions = {
  [Directions.DOWN]: 4,
  [Directions.MIDDLE]: 2,
  [Directions.UP]: 0
};

const RIGHT_CARDS: ICardPositions = {
  [Directions.DOWN]: 5,
  [Directions.MIDDLE]: 3,
  [Directions.UP]: 1
};

export default class GamePage extends Component<IGamePageProps, IGamePageState> {

  public constructor(props: IGamePageProps) {
    super(props);
    this.state = {
      historyCounter: -1,
      history: [],
      lastDirections: {
        left: Directions.UNKNOWN,
        right: Directions.UNKNOWN
      },
      startTime: 0,
      selectedCards: [],
      currentCards: shuffle([1, 2, 3, 4, 5, 6]),
      expectedCards: shuffle([1, 2, 3, 4, 5, 6]),
      endTime: undefined
    };
    this.onChange = this.onChange.bind(this);
    this.getSelectedCards = this.getSelectedCards.bind(this);
  }

  render() {
    const currentCards = this.state.currentCards;
    const expectedCards = this.state.expectedCards;
    let track: "theme" | "none" | "victory" = "none";
    if (this.state.startTime) {
      if (this.state.endTime) {
        track = "victory";
      } else {
        track = "theme";
      }
    }
    return (
      <div className="gamePage">
        <Music track={track}/>
        <div>
          <PoseDetection onChange={this.onChange} videoStream={this.props.videoStream} />
          <PoseIndicator sides={this.state.lastDirections} />
        </div>
        <div className={this.state.endTime ? "expectedCards" : "cardsNotInOrder"}>
          <PuzzleCardsComponent cards={currentCards} selectedCards={this.state.selectedCards} />
        </div>
        <div className="timer">
          {this.renderTimer()}
        </div>
        <div className="expectedCards">
          <PuzzleCardsComponent cards={expectedCards} selectedCards={[]} />
        </div>
      </div>
    )
  }

  public componentDidMount() {
    document.addEventListener('keydown', (event) => {
      const newDir = { ...this.state.lastDirections };
      const num = parseInt(event.key);
      console.log
      if (isNaN(num)) {
        return;
      }
      if (num === 0) {
        newDir.left = Directions.UNKNOWN;
        newDir.right = Directions.UNKNOWN;
      } else {
        switch (num) {
          case 1:
            newDir.left = Directions.DOWN;
            break;
          case 2:
            newDir.right = Directions.DOWN
            break;
          case 4:
            newDir.left = Directions.MIDDLE;
            break;
          case 5:
            newDir.right = Directions.MIDDLE;
            break;
          case 7:
            newDir.left = Directions.UP;
            break;
          case 8:
            newDir.right = Directions.UP;
            break;
        }
      }
      this.setState({
        lastDirections: newDir
      })
    });
  }

  public componentDidUpdate(prevProps: IGamePageProps, prevState: IGamePageState) {
    if (this.state.lastDirections === prevState.lastDirections || this.state.endTime) {
      return;
    }
    const left = this.state.lastDirections.left;
    const right = this.state.lastDirections.right;
    const from = LEFT_CARDS[left];
    const to = RIGHT_CARDS[right];

    if (left === Directions.MIDDLE && right === Directions.MIDDLE) {
      if (this.state.startTime === 0) {
        this.setState({
          startTime: new Date().getTime()
        })
        return;
      }
    }
    let selectedCards = this.getSelectedCards();
    if (from !== undefined && to !== undefined && this.state.startTime) {
      const currentCards = this.swap(this.state.currentCards, from, to);
      const endTime = isEqual(currentCards, this.state.expectedCards) ? new Date().getTime() : undefined;
      if (endTime) {
        selectedCards = [];
      }
      this.setState({
        selectedCards,
        currentCards,
        endTime
      });
    } else {
      this.setState({
        selectedCards
      });
    }
  }

  public onChange(poseDetectionResult: PoseDetectionResult) {
    if (this.state.endTime) {
      if (new Date().getSeconds() % 2 === 0) {
        this.setState({
          lastDirections: {
            left: Directions.DOWN,
            right: Directions.DOWN
          }
        });
      } else {
        this.setState({
          lastDirections: {
            left: Directions.UP,
            right: Directions.UP
          }
        });
      }
      return;
    }
    const newState: IGamePageState = { ...this.state };
    const history = this.state.history;
    let count = this.state.historyCounter + 1;
    if (count > EQUALITY_COUNTER) {
      count = 0
      newState.historyCounter = -1;
    } else {
      newState.historyCounter += 1;
    }

    const directionRight = this.getRightDirection(poseDetectionResult);
    const directionLeft = this.getLeftDirection(poseDetectionResult);

    history[count] = {
      left: directionLeft,
      right: directionRight
    };
    if (history.length > EQUALITY_COUNTER) {
      const lastDirections = { ...this.state.lastDirections };
      let firstDirection = history[0].left;
      let temp = history.filter(d => d.left === firstDirection);
      if (temp.length === history.length) {
        if (lastDirections.left !== firstDirection) {
          lastDirections.left = firstDirection;
          newState.lastDirections = lastDirections;
        }
      }
      firstDirection = history[0].right;
      temp = history.filter(d => d.right === firstDirection);
      if (temp.length === history.length) {
        if (lastDirections.right !== firstDirection) {
          lastDirections.right = firstDirection;
          newState.lastDirections = lastDirections;
        }
      }
    }
    this.setState(newState);
  }

  private getLeftDirection(poseDetectionResult: PoseDetectionResult) {
    let direction = Directions.UNKNOWN;
    const leftShoulder = poseDetectionResult.pose.keypoints[6];
    const leftElbow = poseDetectionResult.pose.keypoints[8];
    const leftWrist = poseDetectionResult.pose.keypoints[10];
    const arm = this.chooseMoreStable(leftElbow, leftWrist);
    if (leftShoulder.score > MIN_PARTS_SCORE && arm.score > MIN_PARTS_SCORE) {
      const angle = this.getAngle(leftShoulder.position, arm.position);

      if (angle < 10 || angle > 330) {
        direction = Directions.MIDDLE;
      } else if (angle > 10 && angle < 100) {
        direction = Directions.UP;
      } else if (angle > 300) {
        direction = Directions.DOWN;
      }
    }
    return direction;
  }

  private getRightDirection(poseDetectionResult: PoseDetectionResult) {
    let direction = Directions.UNKNOWN;
    const rightShoulder = poseDetectionResult.pose.keypoints[5];
    const rightWrist = poseDetectionResult.pose.keypoints[9];
    const rightElbow = poseDetectionResult.pose.keypoints[7];
    const arm = this.chooseMoreStable(rightWrist, rightElbow);
    if (rightShoulder.score > MIN_PARTS_SCORE && arm.score > MIN_PARTS_SCORE) {
      const angle = this.getAngle(rightShoulder.position, arm.position);

      if (angle > 170 && angle < 210) {
        direction = Directions.MIDDLE;
      } else if (angle > 100 && angle < 170) {
        direction = Directions.UP;
      } else if (angle > 210 && angle < 240) {
        direction = Directions.DOWN;
      }
    }
    return direction;
  }

  private chooseMoreStable(a: Keypoint, b: Keypoint) {
    if (a.score > b.score) {
      return a;
    }
    return b;
  }

  private getAngle(v1: Vector2D, v2: Vector2D) {
    const dx = v1.x - v2.x;
    const dy = v1.y - v2.y;
    let theta = Math.atan2(dy, dx);
    theta *= 180 / Math.PI;
    if (theta < 0) theta = 360 + theta;
    return theta;
  }

  private renderTimer() {
    let startTime = this.state.startTime;
    let endTime = this.state.endTime || new Date().getTime()
    if (startTime === 0) {
      return (
        <h1>
          00:00.000s
      </h1>
      );
    }
    const diff = new Date(endTime - startTime);
    return (
      <h1>
        {diff.toISOString().substr(14, 9) + 's'}
      </h1>
    )
  }

  private swap(cards: number[], from: number, to: number) {
    const ret = [...cards];
    const temp = ret[from];
    ret[from] = ret[to];
    ret[to] = temp;
    return ret;
  }

  private getSelectedCards() {
    let selectedCards: number[] = [];
    if (this.state.lastDirections.left === Directions.UNKNOWN && this.state.lastDirections.right === Directions.UNKNOWN) {
      return selectedCards;
    }
    if (this.state.lastDirections.left === Directions.UP) {
      selectedCards[selectedCards.length] = 0
    } else if (this.state.lastDirections.left === Directions.MIDDLE) {
      selectedCards[selectedCards.length] = 2
    } else if (this.state.lastDirections.left === Directions.DOWN) {
      selectedCards[selectedCards.length] = 4
    }
    if (this.state.lastDirections.right === Directions.UP) {
      selectedCards[selectedCards.length] = 1
    } else if (this.state.lastDirections.right === Directions.MIDDLE) {
      selectedCards[selectedCards.length] = 3
    } else if (this.state.lastDirections.right === Directions.DOWN) {
      selectedCards[selectedCards.length] = 5
    }
    return selectedCards;
  }
}